import { lazy, Suspense, useEffect, useState } from 'react';
const PhoneIcon = lazy(() => import('../components/common/svg/Phone'));
const LocationIcon = lazy(() => import('../components/common/svg/Location'));
const Footer = () => {
  const [homeUrl, setHomeUrl] = useState('/');
  useEffect(() => {
    if (localStorage.getItem('STDNTTKN')) {
      setHomeUrl('/home');
    }
  }, []);
  return (
    <div className="bg-gradient-to-b from-footer-bg-top to-footer-bg-bottom">
      <div className="container mx-auto text-white min-h-[330px] lg:px-[18rem] md:px-1 py-8">
        <div className="basis-1 px-2">
          <a href={homeUrl} className="block w-[200px]">
            <img src="/images/logo.png" alt="SiteLogo" />
          </a>
        </div>
        <div className="text-title-1 font-bold text-xl text-center -mt-4 md:mt-2">
          আমাদের অফলাইন শাখা
        </div>
        <div className="flex flex-row md:flex-col justify-center items-center md:items-start px-[6rem] md:px-2">
          <div className="basis-1/2 md:basis-1 py-3 ">
            <div className="text-title-2 font-[500]">Farmgate Branch</div>
            <Suspense fallback={null}>
              <PhoneIcon />
            </Suspense>{' '}
            01324-723301 <br />
            <Suspense fallback={null}>
              <LocationIcon />
            </Suspense>{' '}
            78 Green Road, Farmgate, Dhaka-1215
          </div>
        </div>
        <div className="flex pt-4 px-2">
          <div className="grow shrink text-center">
            Copyright © 2025{' '}
            <span className="text-title-1 font-bold">Bondi</span>{' '}
            <span className="text-title-2 font-bold">Pathshala</span> All rights
            reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
