import { useEffect, useState } from 'react';
import Latex from 'react-latex-next';

const BothRetakeSolution = ({ question, counter = 1, type = 'mcq' }) => {
  const [options, setOptions] = useState(question.options || []);
  console.log(question);
  useEffect(() => {
    if (question.type === false) {
      const loopTill = question.optionCount || 4;
      setOptions(
        Array.from({ length: loopTill }, (_, i) => String.fromCharCode(65 + i)),
      );
    }
  }, [question]);

  const openModal = () => {
    const checkedModal = document.getElementById('explanationModal');
    if (checkedModal) checkedModal.checked = true;
  };

  return (
    <div className="mb-8 pb-2 rounded-lg shadow-[0px_0px_6px_2px_rgba(0,0,0,0.5)]">
      <span className="questionNo">{counter}</span>
      <div className="mb-1">
        {question.type === false ? (
          <div className="p-4">
            <img
              className="rounded-lg"
              src={`${process.env.REACT_APP_FILES_HOST}/${
                type === 'mcq-only' ? question.question : question.iLink
              }`}
              alt="Question"
            />
          </div>
        ) : (
          <div className="rounded-lg text-[1.5rem] font-bold p-4">
            <Latex>
              {question.iLink || question.question || 'Invalid question'}
            </Latex>
          </div>
        )}
      </div>
      {parseInt(question.answeredOption) === -1 && (
        <div className="text-center text-red-500 font-bold text-lg leading-6">
          Not Answered
        </div>
      )}
      {question.type === false ? (
        <ul className="my-3 flex space-x-4 justify-center">
          {options.map((opt, idx) => {
            let defaultTextColor = 'text-black';
            if (parseInt(question.answeredOption) !== -1) {
              if (parseInt(question.correctOption) === idx) {
                defaultTextColor = 'border-green-500';
              }
              if (parseInt(question.answeredOption) === idx) {
                defaultTextColor = 'border-green-500';
              }
              if (
                parseInt(question.answeredOption) !==
                  parseInt(question.correctOption) &&
                parseInt(question.answeredOption) === idx
              ) {
                defaultTextColor = 'border-red-500';
              }
            } else {
              if (parseInt(question.correctOption) === idx) {
                defaultTextColor = 'border-green-500';
              }
            }
            return (
              <li
                className={`mb-0 px-5 md:px-2 py-2 md:py-1 border-4 md:border-3 bg-[#262626] rounded-lg ${defaultTextColor}`}
                key={`soln.${idx}`}
              >
                <div className={`font-semibold text-white`}>
                  {/* {String.fromCharCode(65 + parseInt(idx))}.&nbsp;&nbsp; */}
                  {opt}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="my-3 mx-4 flex justify-center flex-col space-y-2">
          {options.length > 0 &&
            options.map((opt, idx) => {
              let defaultTextColor = 'text-black';
              if (parseInt(question.answeredOption) !== -1) {
                if (parseInt(question.correctOption) === idx) {
                  defaultTextColor = 'border-green-500';
                }
                if (parseInt(question.answeredOption) === idx) {
                  defaultTextColor = 'border-green-500';
                }
                if (
                  parseInt(question.answeredOption) !==
                    parseInt(question.correctOption) &&
                  parseInt(question.answeredOption) === idx
                ) {
                  defaultTextColor = 'border-red-500';
                }
              } else {
                if (parseInt(question.correctOption) === idx) {
                  defaultTextColor = 'border-green-500';
                }
              }
              return (
                <li
                  className={`mb-0 px-5 md:px-2 py-2 md:py-1 border-4 md:border-3 bg-[#262626] rounded-lg ${defaultTextColor}`}
                  key={`soln.${idx}`}
                >
                  <div className={`font-semibold text-white`}>
                    {String.fromCharCode(65 + parseInt(idx))}.&nbsp;&nbsp;
                    <Latex>{opt}</Latex>
                  </div>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default BothRetakeSolution;
