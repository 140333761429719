// import { Suspense, lazy } from 'react';

import HeaderLessLayout from './pages/layouts/HeaderLessLayout';
import Layout from './pages/layouts/Layout';

import AllExams from './pages/AllExams';
import ChangePassword from './pages/ChangePassword';
import EditProfile from './pages/EditProfile';
import ExamBySubject from './pages/ExamBySubject';
import History from './pages/History';
import Home from './pages/Home';
import LeaderBoard from './pages/LeaderBoard';
import MissedExams from './pages/MissedExams';
import Profile from './pages/Profile';

import BeforeStart from './pages/exams/BeforeStart';
import BeforeStartRetake from './pages/exams/BeforeStartRetake';
import ExamRules from './pages/exams/ExamRules';
import ExamSolution from './pages/exams/ExamSolution';
import ExamSolutionWritten from './pages/exams/ExamSolutionWritten';
import OngoingExam from './pages/exams/OngoingExam';

import MissedSpecialRetake from './pages/exams/MissedSpecialRetake';
import RetakeExam from './pages/exams/RetakeExam';
import RetakeMcqSpecial from './pages/exams/RetakeMcqSpecial';
import RetakeSpecial from './pages/exams/RetakeSpecial';

// Free Exam Components
import LandingPage from './pages/LandingPage';
import Login from './pages/auth/Login';
import BothRetake from './pages/exams/BothRetake';

export const pages = [
  {
    element: Layout,
    children: [
      {
        path: '/home',
        element: Home,
      },
      {
        path: '/history',
        element: History,
      },
      {
        path: '/profile',
        element: Profile,
      },
      {
        path: '/leaderboard',
        element: LeaderBoard,
      },
      {
        path: '/:studentId/edit-profile',
        element: EditProfile,
      },
      {
        path: '/change-password',
        element: ChangePassword,
      },
      {
        path: '/missed-exams',
        element: MissedExams,
      },
      {
        path: 'exam-list/:type/all',
        element: AllExams,
      },
      {
        path: 'exam-by-subject/:sId/:variation',
        element: ExamBySubject,
      },
    ],
  },
  {
    element: HeaderLessLayout,
    children: [
      {
        path: '/',
        element: LandingPage,
      },
      {
        element: Login,
        // element: OldLogin,
        path: '/login',
      },
      {
        path: '/exam/:exam_code/before-start/:exam_variation',
        element: BeforeStart,
      },
      {
        path: '/exam/:exam_code/before-start/retake',
        element: BeforeStartRetake,
      },
      {
        path: '/exam/:exam_code/rules/:exam_variation',
        element: ExamRules,
      },
      {
        path: '/exam/:exam_code/ongoing/:exam_variation',
        element: OngoingExam,
      },
      {
        path: '/exam/:exam_code/retake',
        element: RetakeExam,
      },
      {
        path: '/bothexam/:exam_code/retake',
        element: BothRetake,
      },
      {
        path: '/exam/:exam_code/mcq-special-retake',
        element: RetakeMcqSpecial,
      },
      {
        path: '/exam/:exam_code/special-retake',
        element: RetakeSpecial,
      },
      {
        path: '/exam/:exam_code/special-missed',
        element: MissedSpecialRetake,
      },
      {
        path: '/exam/:exam_code/solution',
        element: ExamSolution,
      },
      {
        path: '/exam/:exam_code/writtensolution',
        element: ExamSolutionWritten,
      },
      // {
      //   path:"/free_exam/before-start",
      //   element: FreeBeforeStart,
      // },
      // {
      //   path:"/free_exam/rules",
      //   element: FreeExamRules,
      // },
      // {
      //   path:"/free_exam/ongoing",
      //   element: FreeOngoingExam,
      // }
    ],
  },
];
