import React, { Suspense, lazy, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import v2 from '../assets/img/icons/eye.svg';
import v3 from '../assets/img/icons/rulerpen.svg';
import v1 from '../assets/img/icons/tasks.svg';
import Pagination from '../components/common/Pagination';
import authChecker from '../utils/authChecker';
import axios from '../utils/axios';
// import missed from '../assets/img/icons/missed.svg'
// import Modal from "react-modal";
// import filterLogo from "../assets/img/filter.png";
// import crossImage from "../assets/img/cross.svg";
import { Link, useNavigate } from 'react-router-dom';
import ResultSummeryMcqCombined from '../components/common/v2/ResultSummeryMcqCombined';
const DaisyModal = lazy(() => import('../components/common/v2/ResultSummery'));
const ResultSummeryWritten = lazy(
  () => import('../components/common/v2/ResultSummeryWritten'),
);
const ResultSummeryBoth = lazy(
  () => import('../components/common/v2/ResultSummeryBoth'),
);
const ResultSummeryCombined = lazy(
  () => import('../components/common/v2/ResultSummeryCombined'),
);

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

const History = () => {
  const [TOKEN, setTOKEN] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [examResult, setExamResult] = useState(null);
  const [apiCallDone, setApiCallDone] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    setTOKEN(localStorage.getItem('STDNTTKN'));
    setCourseId(localStorage.getItem('FORCOURSE'));
  });
  useEffect(() => {
    if (TOKEN) {
      authChecker();
      getHistoryData(1, activeTab);
    }
  }, [TOKEN, courseId]);

  const handlePageClick = (event) => {
    let clickedPage = event.selected + 1;
    if (clickedPage > 1) {
      getHistoryData(clickedPage, activeTab);
    } else {
      getHistoryData(1, activeTab);
    }
  };

  function getHistoryData(page = 1, type = 0) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;
    console.log('asdads', page, type);
    setApiCallDone(false);
    if (type === 0) {
      setActiveTab(0);
      axios
        .get('/api/student/history?courseId=' + courseId + '&page=' + page)
        .then(({ data }) => {
          setTableData(data.resultData);
          setPaginationData(data.paginateData);
          setApiCallDone(true);
        })
        .catch((err) => {
          console.log(err);

          setTableData([]);
          setPaginationData([]);
          setApiCallDone(true);
        });
    } else if (parseInt(type) === 1) {
      setActiveTab(1);
      axios
        .get(
          '/api/student/historydatawritten?courseId=' +
            courseId +
            '&page=' +
            page,
        )
        .then(({ data }) => {
          setTableData(data.resultData);
          setPaginationData(data.paginateData);
          setApiCallDone(true);
        })
        .catch((err) => {
          console.log(err);

          setTableData([]);
          setPaginationData([]);
          setApiCallDone(true);
        });
    } else if (parseInt(type) === 2) {
      setActiveTab(2);
      axios
        .get(
          '/api/student/bothhistorydata?courseId=' + courseId + '&page=' + page,
        )
        .then(({ data }) => {
          setTableData(data.resultData);
          setPaginationData(data.paginateData);
          setApiCallDone(true);
        })
        .catch((err) => {
          console.log(err);

          setTableData([]);
          setPaginationData([]);
          setApiCallDone(true);
        });
    } else if (parseInt(type) === 3) {
      setActiveTab(3);
      axios
        .get('/api/special/historydata?courseId=' + courseId + '&page=' + page)
        .then(({ data }) => {
          setTableData(data.resultData);
          setPaginationData(data.paginateData);
          setApiCallDone(true);
        })
        .catch((err) => {
          console.log(err);

          setTableData([]);
          setPaginationData([]);
          setApiCallDone(true);
        });
    } else {
      setActiveTab(4);
      axios
        .get(
          '/api/mcqspecialexam/historydata?courseId=' +
            courseId +
            '&page=' +
            page,
        )
        .then(({ data }) => {
          setTableData(data.resultData);
          setPaginationData(data.paginateData);
          setApiCallDone(true);
        })
        .catch((err) => {
          console.log(err);

          setTableData([]);
          setPaginationData([]);
          setApiCallDone(true);
        });
    }
  }

  function studentSubmittedExamDetail(
    examId,
    endpoint = '/api/student/studentSubmittedExamDetail?examId=',
  ) {
    if (!examId) {
      window.alert("We coudn't find any exam details");
      return;
    }
    // console.log("aaaa",activeTab);
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;
    axios
      .get(endpoint + examId)
      .then(({ data }) => {
        if (activeTab === 0) {
          setExamResult({
            bgColor: 'none',
            result: data,
            customWidth: 'max-w-4xl',
          });
          document.getElementById('my-modal-4').checked = true;
        } else if (activeTab === 1) {
          setExamResult({
            bgColor: 'none',
            result: data,
            customWidth: 'max-w-4xl',
          });
          document.getElementById('my-modal-5').checked = true;
        } else if (activeTab === 2) {
          setExamResult({
            bgColor: 'none',
            result: data,
            customWidth: 'max-w-4xl',
          });
          setTimeout(() => {
            document.getElementById('my-modal-7').checked = true;
          }, 200);
        } else if (activeTab === 3) {
          setExamResult({
            bgColor: 'none',
            result: data,
            customWidth: 'max-w-4xl',
          });
          document.getElementById('my-modal-6').checked = true;
        } else {
          // console.log("at: ",activeTab);
          setExamResult({
            bgColor: 'none',
            result: data,
            customWidth: 'max-w-4xl',
          });
          document.getElementById('my-modal-8').checked = true;
        }
      })
      .catch((err) => {
        window.alert('Something went wrong');
      });
  }

  return (
    <div className="px-28 md:px-4 py-3 lg:py-10">
      <div className="my-10">
        <Link
          to="/missed-exams"
          className=" px-10 py-4 bg-color-one rounded-xl text-white font-semibold"
        >
          Missed Exams
        </Link>
      </div>
      <div className="container mx-auto">
        {/* history table */}
        <div className="overflow-x-auto pt-1 pb-8 min-h-[580px]">
          <div className="tabs bg-transparent rounded-t-lg mt-4 mx-auto">
            <button
              className={`tab tab-lg my-tabs !px-8 tab-lifted ${
                activeTab === 0 ? 'tab-active' : ''
              }`}
              onClick={() => getHistoryData(1, 0)}
            >
              MCQ
            </button>
            <button
              className={`tab tab-lg my-tabs !px-8 tab-lifted ${
                activeTab === 1 ? 'tab-active' : ''
              }`}
              onClick={() => getHistoryData(1, 1)}
            >
              Written
            </button>
            <button
              className={`tab tab-lg my-tabs !px-8 tab-lifted ${
                activeTab === 2 ? 'tab-active' : ''
              }`}
              onClick={() => getHistoryData(1, 2)}
            >
              Both
            </button>
            <button
              className={`tab tab-lg my-tabs !px-8 tab-lifted ${
                activeTab === 3 ? 'tab-active' : ''
              }`}
              onClick={() => getHistoryData(1, 3)}
            >
              Special
            </button>
            <button
              className={`tab tab-lg my-tabs !px-8 tab-lifted ${
                activeTab === 4 ? 'tab-active' : ''
              }`}
              onClick={() => getHistoryData(1, 4)}
            >
              Mcq Special
            </button>
          </div>
          {apiCallDone ? (
            <table className="overflow-x-scroll  table-fixed w-full customTable !mt-0">
              {/* head */}
              <thead>
                {activeTab !== 4 && (
                  <tr className="text-center">
                    <th className="py-5 min-w-full w-[80px]">Sl No.</th>
                    <th className="min-w-full w-[180px]">Date</th>
                    <th className="min-w-full w-[180px]">Title</th>
                    {activeTab !== 3 && activeTab !== 4 && (
                      <th className="min-w-full w-[350px]">Subject</th>
                    )}
                    {activeTab !== 2 && activeTab !== 3 && activeTab !== 4 && (
                      <>
                        <th className="min-w-full w-[160px]">D/W/M</th>
                        <th className="min-w-full w-[160px]">Exam Type</th>
                      </>
                    )}
                    <th className="min-w-full w-[200px]">Marks</th>
                    <th className="min-w-full w-[200px]">Merit Postition</th>
                    {activeTab === 2 ? (
                      <th className={`min-w-full w-[172px]`}>Action</th>
                    ) : activeTab === 3 ? (
                      <th className={`min-w-full w-[290px]`}>Action</th>
                    ) : activeTab === 4 ? (
                      <th className={`min-w-full `}>Action</th>
                    ) : (
                      <th
                        className={`min-w-full w-[${200 - activeTab * 40}px]`}
                      >
                        Action
                      </th>
                    )}
                  </tr>
                )}
                {activeTab === 4 && (
                  <tr className="text-center">
                    <th className="py-5 min-w-full w-[80px]">Sl No.</th>
                    <th className="min-w-full w-[290px] ">Date</th>
                    <th className="min-w-full w-[290px] ">Title</th>
                    <th className="min-w-full w-[290px] ">Marks</th>
                    <th className="min-w-full w-[290px] ">Merit Postition</th>
                    <th className={`min-w-full  w-[150px]`}>Action</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {tableData && tableData.length ? (
                  tableData.map((data, index) => (
                    <tr
                      key={index}
                      className="even:bg-table-row-even odd:bg-table-row-odd text-center w-full "
                    >
                      <th className="py-5">{index + 1}</th>
                      {activeTab === 0 || activeTab === 1 ? (
                        <td>
                          <div className="text-green-600 md:text-sm">
                            {data.examStartTime}
                          </div>
                          {data.examEndTime && (
                            <div className="text-red-600 md:text-sm">
                              {data.examEndTime}
                            </div>
                          )}
                        </td>
                      ) : (
                        <td>
                          <div className="text-green-600 md:text-sm">
                            {data.examStartTimeMcq}
                          </div>
                          {data.examEndTimeWritten && (
                            <div className="text-red-600 md:text-sm">
                              {data.examEndTimeWritten}
                            </div>
                          )}
                        </td>
                      )}
                      <td>{data.title}</td>
                      {activeTab !== 3 && activeTab !== 4 && (
                        <td>{data.subjectName}</td>
                      )}
                      {activeTab !== 2 &&
                        activeTab !== 3 &&
                        activeTab !== 4 && (
                          <>
                            <td>{data.variation}</td>
                            <td>{data.type}</td>
                          </>
                        )}
                      {parseInt(data.totalObtainedMarks) === -5000 ? (
                        <td className="text-red-700">Late submission</td>
                      ) : (
                        <td>
                          {data.totalObtainedMarks ?? 0}/{data.totalMarksMcq}
                        </td>
                      )}
                      <td>
                        {data.meritPosition === -1
                          ? 'Pending'
                          : `${data.meritPosition}/${data?.totalStudent}`}
                      </td>
                      <td>
                        <div className="flex space-x-2 justify-center">
                          {activeTab === 0 && (
                            <>
                              <button
                                onClick={(e) =>
                                  studentSubmittedExamDetail(data.examId)
                                }
                                data-exam-id={data.examId}
                                className="rounded-full text-center disabled:cursor-not-allowed tooltip"
                                data-tip="View Exam Result"
                              >
                                <img
                                  className="h-[38px] w-[38px] inline-block"
                                  src={v1}
                                  alt="quick-view"
                                />
                              </button>
                              {data.meritPosition !== -1 && (
                                <button
                                  className="tooltip rounded-full text-center "
                                  data-tip={
                                    data.meritPosition === -1
                                      ? 'N/A'
                                      : 'Get Solution'
                                  }
                                >
                                  <Link
                                    to={`/exam/${data.examId}/solution?type=mcq`}
                                    className="tooltip rounded-full text-center"
                                    data-tip="Get Solution"
                                  >
                                    <img
                                      className="inline-block h-[38px] w-[38px]"
                                      src={v2}
                                      alt="view-solution"
                                    />
                                  </Link>
                                </button>
                              )}
                              {data.meritPosition === -1 ? (
                                <button
                                  className="tooltip rounded-full text-center disabled:cursor-not-allowed"
                                  disabled
                                  data-tip={
                                    data.meritPosition === -1 ? 'N/A' : 'Retake'
                                  }
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v3}
                                    alt="retake"
                                  />
                                </button>
                              ) : (
                                <Link
                                  to={`/exam/${data.examId}/retake`}
                                  className="tooltip text-center"
                                  data-tip="Retake the exam"
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v3}
                                    alt="retake"
                                  />
                                </Link>
                              )}

                              {data.solutionSheet && (
                                <Link
                                  to={data.solutionSheet}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={
                                    'tooltip text-center tooltip-left bg-yellow-400 rounded-full'
                                  }
                                  data-tip="Solution Sheet"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="p-1 inline-block h-[38px] w-[38px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                                    />
                                  </svg>
                                </Link>
                              )}
                            </>
                          )}

                          {activeTab === 1 && (
                            <>
                              <button
                                onClick={(e) =>
                                  studentSubmittedExamDetail(
                                    data.examId,
                                    '/api/student/examdetailwritten?examId=',
                                  )
                                }
                                data-exam-id={data.examId}
                                className="rounded-full text-center tooltip"
                                data-tip="View Exam Result"
                              >
                                <img
                                  className="h-[38px] w-[38px] inline-block"
                                  src={v1}
                                  alt="quick-view"
                                />
                              </button>
                              <Link
                                to={`/exam/${data.examId}/writtensolution?type=written`}
                                className="tooltip rounded-full text-center"
                                data-tip="Get Solution"
                              >
                                <img
                                  className="inline-block h-[38px] w-[38px]"
                                  src={v2}
                                  alt="view-solution"
                                />
                              </Link>
                              {data.solutionSheet && (
                                <Link
                                  to={data.solutionSheet}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={
                                    'tooltip text-center tooltip-left bg-yellow-400 rounded-full'
                                  }
                                  data-tip="Solution Sheet"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="p-1 inline-block h-[38px] w-[38px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                                    />
                                  </svg>
                                </Link>
                              )}
                            </>
                          )}
                          {activeTab === 2 && (
                            <>
                              <button
                                onClick={(e) =>
                                  studentSubmittedExamDetail(
                                    data.examId,
                                    '/api/student/bothexamdetail?examId=',
                                  )
                                }
                                data-exam-id={data.examId}
                                className="rounded-full text-center tooltip disabled:cursor-not-allowed"
                                data-tip={
                                  Date.meritPosition === -1
                                    ? 'N/A'
                                    : 'View Exam Result'
                                }
                                disabled={
                                  data.meritPosition === -1 ? 'disabled' : ''
                                }
                              >
                                <img
                                  className="h-[38px] w-[38px] inline-block"
                                  src={v1}
                                  alt="quick-view"
                                />
                              </button>
                              {data.meritPosition === -1 ? (
                                <button
                                  className="tooltip rounded-full text-center disabled:cursor-not-allowed"
                                  disabled
                                  data-tip={
                                    data.meritPosition === -1
                                      ? 'N/A'
                                      : 'Get Solution'
                                  }
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v2}
                                    alt="view-solution"
                                  />
                                </button>
                              ) : (
                                <Link
                                  to={`/exam/${data.examId}/solution?type=mcq-both`}
                                  className="tooltip rounded-full text-center"
                                  data-tip="MCQ Solution"
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v2}
                                    alt="view-solution"
                                  />
                                </Link>
                              )}
                              <Link
                                to={`/exam/${data.examId}/writtensolution?type=written-both`}
                                className="tooltip text-center tooltip-left"
                                data-tip="Written Solution"
                              >
                                <img
                                  className="inline-block h-[38px] w-[38px]"
                                  src={v3}
                                  alt="written solution"
                                />
                              </Link>
                              <Link
                                to={`/bothexam/${data.examId}/retake`}
                                className="tooltip text-center"
                                data-tip="Retake Exam"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  // className="size-6"
                                  className="p-1 inline-block h-[38px] w-[38px]"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                  />
                                </svg>
                              </Link>
                              {data.solutionSheet && (
                                <Link
                                  to={data.solutionSheet}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={
                                    'tooltip text-center tooltip-left bg-yellow-400 rounded-full'
                                  }
                                  data-tip="Solution Sheet"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="p-1 inline-block h-[38px] w-[38px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                                    />
                                  </svg>
                                </Link>
                              )}
                            </>
                          )}
                          {activeTab === 3 && (
                            <>
                              <button
                                disabled={
                                  data.meritPosition === -1 ? 'disabled' : ''
                                }
                                onClick={(e) =>
                                  studentSubmittedExamDetail(
                                    data.examId,
                                    '/api/special/studentSubmittedExamDetail?examId=',
                                  )
                                }
                                data-exam-id={data.examId}
                                className="rounded-full text-center tooltip disabled:cursor-not-allowed"
                                data-tip={
                                  data.meritPosition === -1
                                    ? 'N/A'
                                    : 'View Exam Result'
                                }
                              >
                                <img
                                  className="h-[38px] w-[38px] inline-block"
                                  src={v1}
                                  alt="quick-view"
                                />
                              </button>
                              {data.meritPosition === -1 ? (
                                <button
                                  className="tooltip rounded-full text-center disabled:cursor-not-allowed"
                                  disabled
                                  data-tip={
                                    data.meritPosition === -1
                                      ? 'N/A'
                                      : 'Get Solution'
                                  }
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v2}
                                    alt="view-solution"
                                  />
                                </button>
                              ) : (
                                <Link
                                  to={`/exam/${data.examId}/solution?type=mcq-special`}
                                  className="tooltip rounded-full text-center"
                                  data-tip="MCQ Solution"
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v2}
                                    alt="view-solution"
                                  />
                                </Link>
                              )}
                              <Link
                                to={`/exam/${data.examId}/writtensolution?type=written-special`}
                                className="tooltip text-center tooltip-left"
                                data-tip="Written Solution"
                              >
                                <img
                                  className="inline-block h-[38px] w-[38px]"
                                  src={v3}
                                  alt="written solution"
                                />
                              </Link>
                              {data.meritPosition === -1 ? (
                                // <button
                                //   className="tooltip rounded-full text-center disabled:cursor-not-allowed"
                                //   disabled
                                //   data-tip={
                                //     data.meritPosition === -1 ? 'N/A' : 'Retake'
                                //   }
                                // >
                                //   <img
                                //     className="inline-block h-[38px] w-[38px]"
                                //     src={v3}
                                //     alt="retake"
                                //   />
                                // </button>
                                <h1></h1>
                              ) : (
                                <Link
                                  to={`/exam/${data.examId}/special-retake`}
                                  className="tooltip text-center"
                                  data-tip="Retake the exam"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    // className="size-6"
                                    className="p-1 inline-block h-[38px] w-[38px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                  </svg>
                                  {/* Retak */}
                                </Link>
                                // <h1></h1>
                              )}
                              {data.solutionSheet && (
                                <Link
                                  to={data.solutionSheet}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={
                                    'tooltip text-center tooltip-left bg-yellow-400 rounded-full'
                                  }
                                  data-tip="Solution Sheet"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="p-1 inline-block h-[38px] w-[38px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                                    />
                                  </svg>
                                </Link>
                              )}
                            </>
                          )}
                          {activeTab === 4 && (
                            <>
                              <button
                                disabled={
                                  data.meritPosition === -1 ? 'disabled' : ''
                                }
                                onClick={(e) =>
                                  studentSubmittedExamDetail(
                                    data.examId,
                                    '/api/mcqspecialexam/studentSubmittedExamDetail?examId=',
                                  )
                                }
                                data-exam-id={data.examId}
                                className="rounded-full text-center tooltip disabled:cursor-not-allowed"
                                data-tip={
                                  data.meritPosition === -1
                                    ? 'N/A'
                                    : 'View Exam Result'
                                }
                              >
                                <img
                                  className="h-[38px] w-[38px] inline-block"
                                  src={v1}
                                  alt="quick-view"
                                />
                              </button>
                              {data.meritPosition === -1 ? (
                                <button
                                  className="tooltip rounded-full text-center disabled:cursor-not-allowed"
                                  disabled
                                  data-tip={
                                    data.meritPosition === -1
                                      ? 'N/A'
                                      : 'Get Solution'
                                  }
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v2}
                                    alt="view-solution"
                                  />
                                </button>
                              ) : (
                                <Link
                                  to={`/exam/${data.examId}/solution?type=special-mcq`}
                                  className="tooltip rounded-full text-center"
                                  data-tip="Get Solution"
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v2}
                                    alt="view-solution"
                                  />
                                </Link>
                              )}
                              {data.meritPosition === -1 ? (
                                <button
                                  className="tooltip rounded-full text-center disabled:cursor-not-allowed"
                                  disabled
                                  data-tip={
                                    data.meritPosition === -1 ? 'N/A' : 'Retake'
                                  }
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v3}
                                    alt="retake"
                                  />
                                </button>
                              ) : (
                                <Link
                                  to={`/exam/${data.examId}/mcq-special-retake`}
                                  className="tooltip text-center"
                                  data-tip="Retake the exam"
                                >
                                  <img
                                    className="inline-block h-[38px] w-[38px]"
                                    src={v3}
                                    alt="retake"
                                  />
                                </Link>
                              )}

                              {data.solutionSheet &&
                                data.solutionSheet !== 'null' && (
                                  <Link
                                    to={data.solutionSheet}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={
                                      'tooltip text-center tooltip-left bg-yellow-400 rounded-full'
                                    }
                                    data-tip="Solution Sheet"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="p-1 inline-block h-[38px] w-[38px]"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                                      />
                                    </svg>
                                  </Link>
                                )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        activeTab !== 2 && activeTab !== 3 && activeTab !== 4
                          ? 9
                          : 7
                      }
                      className="lg:text-center text-3xl sm:pl-5 py-2 bg-slate-200 text-red-600"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <Skeleton
              count={1}
              height={8}
              baseColor="#FF4B0025"
              highlightColor="#FF4B00"
              className="mt-8"
            ></Skeleton>
          )}
        </div>
        {/* react paginate */}
        <div className="pb-8">
          {paginationData && (
            <Pagination
              pageCount={paginationData.totalPages}
              currentPage={paginationData.currentPage}
              handlePageClick={(e) => handlePageClick(e)}
            />
          )}
        </div>
      </div>
      <Suspense fallback={null}>
        <DaisyModal {...examResult} />
      </Suspense>
      <Suspense fallback={null}>
        <ResultSummeryWritten {...examResult} />
      </Suspense>
      {activeTab === 3 && (
        <Suspense fallback={null}>
          <ResultSummeryCombined {...examResult} />
        </Suspense>
      )}
      {activeTab === 4 && (
        <Suspense fallback={null}>
          <ResultSummeryMcqCombined {...examResult} />
        </Suspense>
      )}
      {examResult && (
        <Suspense fallback={null}>
          <ResultSummeryBoth {...examResult} />
        </Suspense>
      )}
    </div>
  );
};

export default History;
