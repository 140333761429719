import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import RightArrow from '../../components/common/svg/RightArrow';
import authChecker from '../../utils/authChecker';
import axios from '../../utils/axios';

const Toast = lazy(() => import('../../components/common/Toast'));

authChecker();

var debounce = 0;
const Login = () => {
  const inputRegNo = useRef(null);
  const selectCourse = useRef(null);
  const inputPassword = useRef(null);
  const [regNo, setRegno] = useState(null);
  const [password, setPassword] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [courses, setCourses] = useState(null);
  const [error, setError] = useState(null);
  const [wrongPassword, setWrongPassword] = useState('');

  useEffect(() => {
    if (regNo && regNo.length > 3) {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await axios
          .get('/api/coursevsstudent/getcoursebyreg?regNo=' + regNo)
          .then(({ data }) => {
            if (data) {
              setError(null);
              setCourses(data.courses);
              selectCourse.current.classList.add('show');
            } else {
              setCourses(null);
              setError({
                message: 'No course found for the student',
                cssClass: 'alert-error',
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setCourses(null);
            setError({
              message: 'No course found for the student',
              cssClass: 'alert-error',
            });
          });
      }, 800);
    }
  }, [regNo]);

  async function doLogin() {
    if (!regNo || !courseId || !password) {
      return;
    } else {
      let registration = {
        regNo: regNo.trim(),
        password,
        courseId,
      };
      console.log(registration);
      // return;
      await axios
        .post('/api/student/newlogin', registration)
        .then(({ data }) => {
          setWrongPassword('');
          if (data.token) {
            localStorage.setItem('STDNTTKN', data.token);
            localStorage.setItem('STDNTID', data.studentIdStr);
            localStorage.setItem('FORCOURSE', data.courseIdStr);
            window.location.href = '/home';
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setWrongPassword(err.response.data.message);
        });
    }
  }

  return (
    <>
      <div className="flex flex-row bg-white text-center mb-5">
        <div className="h-[68px] mx-auto">
          <Link to="/">
            <img src="/images/logo.png" alt="logo" className="w-64" />
          </Link>
        </div>
      </div>
      <div className="mt-1 flex justify-center items-center ">
        <div>
          <p className="text-2xl font-extrabold text-color-one text-center">
            তোমার Password : bpexam
          </p>
          <p className="text-color-one text-xl font-semibold text-center">
            এই পাসওয়ার্ডটি অবশ্যই আপডেট করে নিতে হবে। কিভাবে পাসওয়ার্ড আপডেট
            করবে দেখতে ....
            <br />
            <Link to="https://youtu.be/_b7BHAn0Kas" target="_blank">
              <span className="underline font-extrabold text-red-500">
                এখানে ক্লিক কর
              </span>
            </Link>
          </p>
        </div>
      </div>
      <div className="container max-w-lg mx-auto min-h-[50vh] items-center ">
        <Suspense fallback={null}>
          <Toast {...error} />
        </Suspense>
        <div className="flex flex-row flex-wrap md:flex-nowrap px-4 justify-center  md:w-full">
          <div className="basis-full">
            <div
              className="form-control w-full formEl"
              onClick={() => {
                inputRegNo.current.classList.add('show');
                inputPassword.current.classList.add('show');
              }}
            >
              <label className="label formLabel self-start text-white relative w-full">
                <span className="label__icon shadow-[4px_2px_6px_0px_rgba(0,0,0,0.5)]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12 md:w-8 md:h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                    />
                  </svg>
                </span>
                <span className="label__text">রেজিস্ট্রেশন নাম্বার</span>
              </label>
              <div className="input__container" ref={inputRegNo}>
                {/* <div> */}
                <input
                  type="text"
                  placeholder="Enter Registration No."
                  className="input input-bordered rounded-lg w-full"
                  onChange={(event) => setRegno(event.target.value)}
                />
              </div>
              {/* </div> */}
            </div>
            <div
              className="form-control w-full formEl"
              onClick={() => {
                inputPassword.current.classList.add('show');
              }}
            >
              <label className="label formLabel self-start text-white relative w-full">
                <span className="label__icon shadow-[4px_2px_6px_0px_rgba(0,0,0,0.5)]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12 md:w-8 md:h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                    />
                  </svg>
                </span>
                <span className="label__text">পাসওয়ার্ড</span>
              </label>
              <div className="input__container" ref={inputPassword}>
                {/* <div> */}
                <input
                  type="password"
                  placeholder="Enter Password."
                  className="input input-bordered rounded-lg w-full"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              <p className="text-center text-red-500 text-xl font-bold">
                {wrongPassword}
              </p>
              {/* </div> */}
            </div>
            <div className="form-control w-full formEl">
              <label className="label formLabel self-start text-white relative w-full">
                <span className="label__icon shadow-[4px_2px_6px_0px_rgba(0,0,0,0.5)]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12 md:w-8 md:h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                    />
                  </svg>
                </span>
                <span className="label__text">তোমার কোর্সটি সিলেক্ট কর</span>
              </label>
              <div className="input__container" ref={selectCourse}>
                <select
                  className="select input input-bordered w-full rounded-sm"
                  onChange={(e) => setCourseId(e.target.value)}
                  defaultValue=""
                  disabled={!courses || courses.length == 0}
                >
                  <option value="">Select your preferred course</option>
                  {courses &&
                    courses.length > 0 &&
                    courses.map((course, index) => (
                      <option key={index} value={course._id}>
                        {course.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap md:flex-nowrap px-4 items-center">
          <div className="basis-full px-6 md:px-4 ml-2">
            <button
              className="text-xl btn btn-hover border-0 rounded-md mt-8 mb-2 disabled"
              onClick={doLogin}
              disabled={!regNo || !courseId || !password}
            >
              {/* <span className='relative block'> */}
              Login
              <span className=" btn-hover_icon">
                <RightArrow />
              </span>
              {/* </span> */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
