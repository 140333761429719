import { useEffect, useState } from 'react';
import Latex from 'react-latex-next';

const QuestionWithSolutionMcq = ({ question, counter = 1, type = 'mcq' }) => {
  const [options, setOptions] = useState(question.options || []);
  // console.log(question);
  useEffect(() => {
    if (question.type === false) {
      const loopTill = question.optionCount || 4;
      setOptions(
        Array.from({ length: loopTill }, (_, i) => String.fromCharCode(65 + i)),
      );
    }
  }, [question]);

  const openModal = () => {
    const checkedModal = document.getElementById('explanationModal');
    if (checkedModal) checkedModal.checked = true;
  };

  return (
    <div className="mb-8 pb-2 rounded-lg shadow-[0px_0px_6px_2px_rgba(0,0,0,0.5)] relative">
      <span className="questionNo">{counter}</span>
      <div className="mb-1">
        {question.type === false ? (
          <div className="p-4">
            <img
              className="rounded-lg w-full"
              src={`${process.env.REACT_APP_FILES_HOST}/${
                type === 'mcq-only' ? question.question : question.iLink
              }`}
              alt="Question"
            />
          </div>
        ) : (
          <div className="rounded-lg text-[1.5rem] font-bold p-4">
            <Latex>{question.iLink ||question.question || 'Invalid question'}</Latex>
          </div>
        )}
      </div>
      {parseInt(question.answeredOption) === -1 && (
        <div className="text-center text-red-500 font-bold text-lg leading-6">
          Not Answered
        </div>
      )}
      <ul className="my-3 flex space-x-4 justify-center">
        {options.map((opt, idx) => {
          const isCorrect = parseInt(question.correctOptions) === idx;
          const isSelected = parseInt(question.answeredOption) === idx;
          const defaultTextColor = isCorrect
            ? 'border-green-500'
            : isSelected
            ? 'border-red-500'
            : 'text-black';

          return (
            <li
              key={`soln.${idx}`}
              className={`mb-0 px-5 md:px-2 py-2 md:py-1 border-4 md:border-3 bg-[#262626] rounded-lg ${defaultTextColor}`}
            >
              <div className="font-semibold text-white">
                {String.fromCharCode(65 + idx)}.&nbsp;&nbsp;
                <Latex>{opt || 'Invalid option'}</Latex>
              </div>
            </li>
          );
        })}
      </ul>
      {question.explanationILink && (
        <div className="mx-4 mt-6 mb-2 text-center">
          <div className="p-2 relative rounded-md border-2 border-orange-500">
            <div className="explanationTitle bg-orange-600 text-white absolute rounded-md px-2 -top-[12px] left-[50%] -translate-x-[50%]">
              Explanation
            </div>
            <p className="p-2">
              <img
                className="rounded-md"
                src={`${process.env.REACT_APP_FILES_HOST}/${question.explanationILink}`}
                alt="Explanation"
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionWithSolutionMcq;